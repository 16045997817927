<template>
  <div ref="header" class="wrapper">
    <el-row class="container">
      <el-col :span="24" class="topbar-wrap">
        <div class="left-header">
          <img class="topbar-logo" :src="logoUrl" @click="click_logo()" />
          <div class="head-header" @click="click_logo()">
            <!-- <h1>AutoMind</h1> -->
            <img class="name_logo" :src="nameLogoUrl" alt="" />
          </div>
          <div v-if="isHome" class="head-program">
            <el-dropdown
              trigger="click"
              :hide-on-click="false"
              @command="programClick"
            >
              <el-tooltip
                v-ak-tooltip-auto-show
                effect="dark"
                :content="program_name"
                placement="right"
              >
                <span
                  style="
                    display: inline-block;
                    height: 30px;
                    display: flex;
                    align-items: center;
                  "
                >
                  <h2
                    style="
                      color: white;
                      margin-left: 12px;
                      max-width: 200px;
                      text-overflow: ellipsis;
                      text-wrap: nowrap;
                      overflow: hidden;
                      height: 30px;
                      line-height: 30px;
                    "
                  >
                    {{ program_name }}
                  </h2>
                  <i
                    class="el-icon-arrow-down el-icon--right"
                    style="color: white; font-size: 16px; font-weight: bold"
                  ></i>
                </span>
              </el-tooltip>
              <el-dropdown-menu
                slot="dropdown"
                style="max-height: 400px; overflow-y: auto; overflow-x: hidden"
                class="dropdown_big homeTopbar_dropdown_offTop"
                ref="zdh_top_hometopbar"
              >
                <el-dropdown-item v-if="loading"
                  >{{ login_text }} <i class="el-icon-loading"></i>
                </el-dropdown-item>
                <el-dropdown-item
                  command="program_add_zdh"
                  class="program_add_zdh"
                >
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      min-height: 40px;
                    "
                  >
                    <el-input
                      v-model="search_PROJECT_zdh"
                      style="width: 290px; box-sizing: border-box; height: auto"
                      class="search_ipt"
                      size="mini"
                      suffix-icon="el-icon-search"
                    >
                    </el-input>
                    <i
                      class="iconfont icon-add"
                      style="color: rgb(48, 100, 143); margin-left: 15px"
                      @click.stop="search_PROJECT_zdh_add"
                    ></i>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item
                  v-for="(column, index) in program_name_list"
                  :key="index"
                  class="list_flex"
                  :command="column"
                  :v-model="column"
                >
                  <personal-avatar
                    :size="33"
                    :avatar="avatar_p[index]"
                    :nickname="program_name_list[index]"
                    :colorid="id_list[index]"
                    :is-project-dropdown="true"
                  ></personal-avatar>
                  <el-tooltip
                    effect="dark"
                    :content="column"
                    :disabled="!isShowToolTip"
                    placement="top"
                  >
                    <div
                      class="porject_name"
                      @mouseenter="(event) => ifShowToolTip(event)"
                    >
                      {{ column }}
                    </div>
                  </el-tooltip>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div
            v-if="!isPersonal && !isWorksapce && !isNotification"
            class="head-create-file"
          >
            <div v-limits-of-authority="'NODE_CREATE'">
              <create-new-file :istop="true">
                <el-button
                  id="zdh_guide_tip1_btn1"
                  :disabled="limits_of_authority_disable('NODE_CREATE')"
                  class="head-create-file__btn"
                >
                  <span class="add">+&ensp;</span>
                  <span class="text">{{ $t("btn.newBtn") }}</span>
                </el-button>
              </create-new-file>
            </div>
          </div>
          <div v-if="isWorksapce" class="head-choose-project">
            <el-select
              v-model="worksapce_choose"
              filterable
              :placeholder="$t('placeholder.dropdown')"
            >
              <el-option
                v-for="item in $store.getters.t_joined_projects"
                :key="item.projectId"
                :label="item.projectName"
                :value="item.projectId"
              >
              </el-option>
            </el-select>
          </div>

          <!--添加项目弹窗  -->
          <el-dialog
            :close-on-click-modal="false"
            :show-close="true"
            custom-class="create_project"
            :visible.sync="create_project_dialog_visiable"
            width="25%"
            style="text-align: left"
            :title="$t('homeTopBar.newProjectForm.title')"
          >
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="130px"
              label-position="left"
            >
              <el-form-item prop="projectName">
                <template slot="label">
                  <span>{{ $t("homeTopBar.newProjectForm.name") }}</span>
                </template>
                <el-input
                  v-model="ruleForm.projectName"
                  maxlength="50"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item prop="projectKey">
                <template slot="label">
                  <span>{{ $t("homeTopBar.newProjectForm.key") }}</span>
                </template>
                <el-input
                  v-model="ruleForm.projectKey"
                  maxlength="10"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item v-if="!ruleForm.templateProjectId" prop="type">
                <template slot="label">
                  <span>{{ $t("homeTopBar.newProjectForm.agileType") }}</span>
                  <el-popover placement="top-start" width="250" trigger="hover">
                    <p style="text-align: left">
                      {{ $t("homeTopBar.newProjectForm.agileDesc") }}
                    </p>
                    <p style="text-align: left">
                      {{ $t("homeTopBar.newProjectForm.kanban") }}
                    </p>
                    <i
                      slot="reference"
                      class="iconfont icon-help"
                      style="margin-left: 5px; transform: scale(1.2)"
                    ></i>
                  </el-popover>
                </template>
                <el-select
                  v-model="ruleForm.type"
                  filterable
                  style="width: 100%"
                >
                  <el-option label="Kanban" value="KANBAN"></el-option>
                  <el-option label="Scrum" value="SCRUM"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="type">
                <template slot="label">
                  <span>{{ $t("homeTopBar.newProjectForm.template") }}</span>
                  <el-popover placement="top-start" width="250" trigger="hover">
                    <p style="text-align: left">
                      {{ $t("homeTopBar.newProjectForm.tempDesc") }}
                    </p>
                    <i
                      slot="reference"
                      class="iconfont icon-help"
                      style="margin-left: 5px; transform: scale(1.2)"
                    ></i>
                  </el-popover>
                </template>
                <el-select
                  v-model="ruleForm.templateProjectId"
                  :loading="templatePidSelectLoading"
                  filterable
                  :placeholder="$t('placeholder.dropdown')"
                  style="width: 100%"
                  :clearable="true"
                  @change="handlerPidSelect"
                >
                  <el-option
                    v-for="item in payTemplate"
                    :key="item.payTemplateName"
                    :value="item.payTemplateId"
                    :label="item.payTemplateLabel"
                  >
                    <template #default>
                      <div
                        style="
                          width: 100%;
                          height: 100%;
                          display: flex;
                          justify-content: space-between;
                        "
                        @click="
                          hanlderClickPayTemplate(
                            item.payStatus,
                            item.payTemplateId
                          )
                        "
                      >
                        <span>{{ item.payTemplateLabel }}</span
                        ><span
                          :style="{
                            color: +item.payStatus
                              ? 'rgba(42, 130, 228, 1)'
                              : 'rgba(166, 166, 166, 1)',
                          }"
                          >{{
                            item.payStatus
                              ? $t("certificate.Purchased")
                              : $t("certificate.Unpurchased")
                          }}</span
                        >
                      </div>
                    </template>
                  </el-option>
                  <el-option
                    v-for="item in all_project"
                    :key="item.projectId"
                    :label="item.projectName"
                    :value="item.projectId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :label="$t('homeTopBar.newProjectForm.avatar')"
                prop="avatar"
                class="avatar"
              >
                <personal-avatar
                  :size="50"
                  :avatar="imageUrl"
                  :nickname="ruleForm.projectName"
                  :is-project-dropdown="true"
                ></personal-avatar>
                <el-upload
                  class="avatar-uploader"
                  action=""
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  accept=".jpg,jpeg,.png"
                  :http-request="uploadFile"
                  :on-error="handleFileError"
                  :before-upload="beforeAvatarUpload"
                >
                  <div size="small" class="upload_avatar">
                    {{ $t("homeTopBar.newProjectForm.editBtn") }}
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item
                :label="$t('homeTopBar.newProjectForm.member')"
                prop="accountIdList"
              >
                <el-select
                  v-model="ruleForm.accountIdList"
                  style="width: 100%"
                  multiple
                  filterable
                >
                  <el-option
                    v-for="item in userListFiltered"
                    :key="item.accountId"
                    :label="item.nickname"
                    :value="item.accountId"
                  >
                    <div class="avatar-and-name">
                      <personal-avatar
                        :size="20"
                        :avatar="item.avatar"
                        :nickname="item.nickname"
                        :colorid="item.accountId"
                      ></personal-avatar>
                      <span style="margin-left: 5px; line-height: 20px">{{
                        item.nickname
                      }}</span>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button
                type="primary"
                :loading="addPLoading"
                @click="addproject('ruleForm')"
                >{{ $t("btn.addBtn") }}
              </el-button>
            </div>
          </el-dialog>
        </div>

        <!-- 右侧 -->
        <div class="right-header">
          <!-- 搜索 -->
          <div class="search-box-hometopbar">
            <!-- <el-select
              ref="select"
              v-model="selectedFileOrNode"
              style="width: 320px"
              class="select"
              collapse-tags
              filterable
              remote
              reserve-keyword
              :placeholder="$t('homeTopBar.search')"
              :remote-method="searchAllByFuzzyKey"
              :loading="loading"
              @change="openNew"
            >
              <el-option-group
                v-for="group in allNodesAndFiles"
                :key="group.label"
                :label="group.label"
              >
                <el-option
                  v-for="item in group.options"
                  :key="
                    group.label === $t('homeTopBar.file')
                      ? item.fileKey
                      : item.nodeKey
                  "
                  :label="
                    group.label === $t('homeTopBar.file')
                      ? `${cut(item.fileKey + '  ' + item.fileName)}`
                      : `${cut(item.nodeKey + '  ' + item.topic)}`
                  "
                  :value="
                    group.label === $t('homeTopBar.file')
                      ? `${item.fileKey}:file:${item.projectId}`
                      : `${item.nodeKey}:node:${item.projectId}`
                  "
                >
                </el-option>
              </el-option-group>
            </el-select> -->
            <!-- 修改-搜索框 -->
            <el-popover
              ref="popover"
              style="position: relative; left: 30px"
              :offset="65"
              class="sea_tab"
              :visible-arrow="false"
              placement="bottom"
              width="440"
              trigger="focus"
            >
              <!-- tab 面板 -->
              <div @click="get_data_to_ipt">
                <el-tabs v-model="activeNameSea" @tab-click="handleClickSea">
                  <!-- file文件 -->
                  <el-tab-pane
                    style="height: 500px !important; overflow: auto !important"
                    :lazy="true"
                    :label="$t('homeTopBar.file')"
                    name="file"
                  >
                    <div class="have">
                      <div
                        v-for="(item, index) in allSearchResults.files || []"
                        :key="index"
                        class="sea_con"
                        @click="
                          openNew(`${item.fileKey}:file:${item.projectId}`)
                        "
                      >
                        <!-- {{ item.fileKey + " " + item.fileName }} -->
                        <select-icon
                          :item="item"
                          :file="true"
                          :key-word="searchKey"
                        >
                        </select-icon>
                      </div>
                    </div>
                  </el-tab-pane>
                  <!-- node节点 -->
                  <el-tab-pane
                    style="height: 500px !important; overflow: auto !important"
                    :lazy="true"
                    :label="$t('homeTopBar.node')"
                    name="node"
                  >
                    <div class="have">
                      <div
                        v-for="(item, index) in allSearchResults.nodes || []"
                        :key="index"
                        class="sea_con"
                        @click="
                          openNew(`${item.nodeKey}:node:${item.projectId}`)
                        "
                      >
                        <!-- {{ item.nodeKey + " " + item.topic }} -->
                        <select-icon :item="item" :key-word="searchKey">
                        </select-icon>
                        <span
                          v-if="searchKey != '' && item.pureDescription != ''"
                        >
                          <span
                            v-for="(flag, list) in setShowtext(
                              item.pureDescription
                            )"
                            :key="list"
                            style="
                              display: inline-block;
                              margin-top: 10px;
                              padding-right: 20px;
                              padding: 6px 14px;
                            "
                          >
                            <span v-html="highlightText(flag)"></span>
                            <span
                              v-if="
                                setShowtext(item.pureDescription).length != 1
                              "
                              style="color: black"
                              >&nbsp;&nbsp;&nbsp;</span
                            >
                          </span>
                        </span>
                        <span
                          v-if="searchKey == '' && item.pureDescription != ''"
                        >
                          <span
                            style="
                              display: inline-block;
                              margin-top: 10px;
                              padding-right: 20px;
                              padding: 6px 14px;
                            "
                          >
                            {{ item.pureDescription }}
                          </span>
                        </span>
                      </div>
                    </div>
                  </el-tab-pane>
                  <!--                   知识库knowledge -->
                  <el-tab-pane
                    style="height: 500px !important; overflow: auto !important"
                    :lazy="true"
                    :label="$t('homeTopBar.knowledge')"
                    name="knowledge"
                  >
                    <div class="have">
                      <div
                        v-for="(item, index) in allSearchResults.knowledges ||
                        []"
                        :key="index"
                        class="sea_konow"
                        @click="
                          openNew(
                            `${item.documentId}:knowledge:${item.projectId}`
                          )
                        "
                      >
                        <div
                          style="
                            font-size: 20px;
                            font-weight: 800;
                            color: black;
                          "
                          v-html="highlightText(showToptitle(item.title))"
                        ></div>
                        <span
                          v-for="(flag, list) in setShowtext(item.insertText)"
                          :key="list"
                          style="display: inline-block; margin-top: 10px"
                        >
                          <span v-html="highlightText(flag)"></span>
                          <span
                            v-if="setShowtext(item.insertText).length != 1"
                            style="color: black"
                            >&nbsp;&nbsp;&nbsp;</span
                          >
                        </span>
                      </div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
              <el-input
                ref="sea_ipt"
                slot="reference"
                v-model="searchKey"
                style="width: 290px; box-sizing: border-box; height: 30px"
                class="search_ipt"
                @blur="loseBlur"
                @focus="searchFuzzy(searchKey)"
                @input="searchFuzzy(searchKey)"
              >
              </el-input>
            </el-popover>

            <i
              class="iconfont icon-zs"
              style="
                cursor: pointer;
                font-size: 18px;
                color: white;
                margin-top: 2px;
              "
            />
            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <circle cx="10" cy="10" r="7"></circle>
              <line x1="21" y1="21" x2="15" y2="15"></line>
            </svg> -->
          </div>
          <!-- <div style="margin-right: 30px; cursor: pointer">
            <el-dropdown
              size="medium"
              @command="worksapcehandleClick"
              ref="messageDrop"
            >
              <div class="head-img" id="right-header-dot">
                <i
                  class="el-icon-more"
                  :style="
                    (has_driver ? 'color: rgb(0 0 0);' : 'color: #fff;') +
                    'cursor: pointer;font-size: 20px;'
                  "
                ></i>
              </div>
              <el-dropdown-menu slot="dropdown" class="menu-item">
                <el-dropdown-item v-if="!isWorksapce" command="workbench">
                  <i
                    class="iconfont icon-zhome"
                    style="cursor: pointer; color: #037abc; font-size: 18px"
                  />{{ $t("menu.workbench") }}
                </el-dropdown-item>
                <el-dropdown-item command="asyncTask" divided>
                  <i
                    class="el-icon-s-order"
                    style="cursor: pointer; color: #037abc; font-size: 18px"
                  />{{ $t("menu.asyncTask") }}
                </el-dropdown-item>
                <el-dropdown-item command="question" divided id="question">
                  <i
                    class="iconfont icon-zwen"
                    style="cursor: pointer; color: #037abc; font-size: 16px"
                  />{{ $t("menu.question") }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
          <!-- </div> -->
          <div @click="goWorkbench">
            <el-tooltip class="item" effect="dark" placement="bottom">
              <div slot="content">
                {{ $t("menu.workbench") }}
              </div>
              <i
                class="iconfont icon-gongzuotaianniu"
                style="
                  cursor: pointer;
                  color: #fff;
                  font-size: 20px;
                  margin-right: 30px;
                "
              />
            </el-tooltip>
          </div>
          <div @click="goAsyncTask">
            <el-tooltip class="item" effect="dark" placement="bottom">
              <div slot="content">
                {{ $t("menu.asyncTask") }}
              </div>
              <i
                class="iconfont icon-renwuliebiaoanniu"
                style="
                  cursor: pointer;
                  color: #fff;
                  font-size: 20px;
                  margin-right: 30px;
                "
              />
            </el-tooltip>
          </div>
          <div
            v-if="isHome || isPersonal || isWorksapce"
            style="cursor: pointer; margin-right: 30px"
            class="bell"
          >
            <div v-if="notice_num > 0" @click="$router.push('/notification')">
              <el-badge :value="notice_num" class="item" :max="99">
                <i
                  class="iconfont icon-xiaoxitishianniu"
                  style="cursor: pointer; color: #fff; font-size: 21px"
                  @click="$router.push('/notification')"
                />
              </el-badge>
            </div>
            <i
              v-else
              class="iconfont icon-xiaoxitishianniu"
              style="cursor: pointer; color: #fff; font-size: 21px"
              @click="$router.push('/notification')"
            />
          </div>
          <div
            v-if="isHome || isNotification || isWorksapce"
            style="margin-right: 30px"
          >
            <el-dropdown size="medium" @command="handleClick">
              <div
                id="right-header-img"
                class="head-img"
                @click="handleClickUser"
              >
                <personal-avatar
                  :size="21"
                  :avatar="avatar"
                  :nickname="userName"
                  :colorid="user_id"
                  placement="right"
                ></personal-avatar>
              </div>
              <el-dropdown-menu slot="dropdown" class="menu-item">
                <el-dropdown-item command="profile"
                  >{{ $t("homeTopBar.menu.profile") }}
                </el-dropdown-item>
                <el-dropdown-item command="logout" divided
                  >{{ $t("homeTopBar.menu.logout") }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- <div style="margin-right: 30px">
            <el-dropdown size="medium" @command="changeTheme">
              <div id="right-header-img" class="head-img">
                <i
                  class="iconfont icon-a-theme"
                  style="cursor: pointer; color: #fff; font-size: 20px"
                />
              </div>
              <el-dropdown-menu slot="dropdown" class="menu-item">
                <el-dropdown-item command=""
                  >{{ $t("homeTopBar.menu.blue") }}
                </el-dropdown-item>
                <el-dropdown-item command="dark"
                  >{{ $t("homeTopBar.menu.dark") }}
                </el-dropdown-item>
                <el-dropdown-item command="light"
                  >{{ $t("homeTopBar.menu.light") }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div> -->
          <!--  -->

          <div v-if="isLocale === 'true'" style="margin-right: 30px">
            <el-dropdown
              size="medium"
              placement="bottom"
              @command="handleClickLocale"
            >
              <i
                class="iconfont icon-yuyananniu"
                style="cursor: pointer; font-size: 20px; color: #fff"
              ></i>
              <el-dropdown-menu slot="dropdown" class="menu-item">
                <el-dropdown-item command="zh"> 简体中文 </el-dropdown-item>
                <el-dropdown-item command="en" divided
                  >English
                </el-dropdown-item>
                <!--                <el-dropdown-item command="de" divided>Deutsch</el-dropdown-item>-->
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      width="80%"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="asyncTaskDialogVisible"
    >
      <template slot="title"
        >{{ $t("menu.asyncTask") }}
        <i
          class="el-icon-refresh-right"
          style="cursor: pointer"
          @click="refreshAsyncTask"
        ></i>
      </template>
      <el-table
        v-loading="asyncTaskTableLoading"
        :data="asyncTaskTableData"
        :header-cell-style="{
          color: '#383838',
          'font-weight': '700',
        }"
        :cell-style="{
          color: '#636363',
          'font-weight': '700',
        }"
        style="width: 100%"
      >
        <el-table-column
          prop="index"
          width="100"
          :label="$t('homeTopBar.asyncTask.index')"
        >
          <template scope="scope">
            <div>
              {{
                scope.$index +
                (asyncTaskTablePageObj.currentPage - 1) *
                  asyncTaskTablePageObj.pageSize +
                1
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('homeTopBar.asyncTask.type')"
          min-width="200"
        >
          <template slot-scope="scope">
            <div>
              {{ asyncTaskType[scope.row.asyncTaskType] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('homeTopBar.asyncTask.status')"
          min-width="120"
        >
          <template slot-scope="scope">
            <span class="status_tag">
              <el-tooltip
                v-ak-tooltip-auto-show
                effect="dark"
                placement="top"
                :content="asyncTaskStatus[scope.row.asyncTaskStatus]"
              >
                <div
                  :class="{
                    status: true,
                    status_ASYNC_TASK_INITIALIZING:
                      scope.row.asyncTaskStatus == 'ASYNC_TASK_INITIALIZING',
                    status_ASYNC_TASK_IN_PROGRESS:
                      scope.row.asyncTaskStatus == 'ASYNC_TASK_IN_PROGRESS',
                    status_ASYNC_TASK_COMPLETED:
                      scope.row.asyncTaskStatus == 'ASYNC_TASK_COMPLETED',
                    status_ASYNC_TASK_ERROR:
                      scope.row.asyncTaskStatus == 'ASYNC_TASK_ERROR',
                  }"
                >
                  {{ asyncTaskStatus[scope.row.asyncTaskStatus] }}
                </div>
              </el-tooltip>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          property="createdAt"
          :label="$t('homeTopBar.asyncTask.startTime')"
          min-width="200"
        >
        </el-table-column>
        <el-table-column
          property="updatedAt"
          :label="$t('homeTopBar.asyncTask.endTime')"
          min-width="200"
        >
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.asyncTaskStatus === 'ASYNC_TASK_COMPLETED' ||
                scope.row.asyncTaskStatus === 'ASYNC_TASK_ERROR'
              "
            >
              {{ scope.row.updatedAt }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('homeTopBar.asyncTask.response')"
          min-width="200"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.asyncTaskStatus === 'ASYNC_TASK_COMPLETED'">
              <div
                v-if="
                  scope.row.asyncTaskType === 'EXCEL_TO_FILE' ||
                  scope.row.asyncTaskType === 'WORD_TO_FILE' ||
                  scope.row.asyncTaskType === 'AI_CREATE_FILE' ||
                  scope.row.asyncTaskType === 'CONTENT_CREATE_FILE'
                "
                style="color: rgb(0, 0, 238); cursor: pointer"
                @click="jumpToFile(scope.row)"
              >
                {{ scope.row.output.fileKey }}
              </div>
              <div v-else-if="scope.row.asyncTaskType === 'DOCUMENT_TO_PDF'">
                <span
                  style="color: rgb(0, 0, 238); cursor: pointer"
                  @click="downloadPdf(scope.row.output.ossPath)"
                >
                  {{ scope.row.input.htmlToPdfDto.title }}
                </span>
              </div>
              <div v-else-if="scope.row.asyncTaskType === 'DOCUMENT_TO_WORD'">
                <a :href="scope.row.output.ossPath">
                  {{ scope.row.input.exportKnowledgeDocWordDto.title }}
                </a>
              </div>
              <div v-else-if="scope.row.asyncTaskType === 'VERSION_TO_EXCEL'">
                <a :href="scope.row.output.ossPath">
                  {{ scope.row.input.versionExportDto.versionName }}
                </a>
              </div>
              <div v-else-if="scope.row.asyncTaskType === 'GANTT_TO_EXCEL'">
                <a :href="scope.row.output.ossPath">
                  {{ scope.row.input.ganttExportDto.ganttName }}
                </a>
              </div>
              <div
                v-else-if="
                  scope.row.asyncTaskType === 'FILE_TO_EXCEL' ||
                  scope.row.asyncTaskType === 'FILE_TO_WORD' ||
                  scope.row.asyncTaskType === 'FILE_TO_CSV'
                "
              >
                <a :href="scope.row.output.ossPath">
                  {{ scope.row.input.fileKey }}
                </a>
              </div>
              <div
                v-else-if="
                  scope.row.asyncTaskType === 'NODE_LIST_TO_EXCEL' ||
                  scope.row.asyncTaskType === 'TWO_DIMENSION_COVERAGE'
                "
              >
                <a :href="scope.row.output.ossPath">
                  {{ $t("file.table.options.download") }}
                </a>
              </div>
              <div v-else-if="scope.row.asyncTaskType === 'DFMEA_TO_EXCEL'">
                <a :href="scope.row.output.ossPath">
                  {{ scope.row.input.fileKey }}
                </a>
              </div>
              <div
                v-else-if="scope.row.asyncTaskType === 'MGPT_CREATE_PRODUCT'"
                style="cursor: pointer; color: rgb(0, 0, 238); font-weight: 700"
                @click="goToProject(scope.row.output.projectId)"
              >
                {{ scope.row.projectName }}
              </div>
              <div
                v-else-if="scope.row.asyncTaskType === 'FILE_ARCHIVE'"
                style="cursor: pointer; color: rgb(0, 0, 238); font-weight: 700"
                @click="getFileArchive(scope.row.input)"
              >
                <a href="javascript:void(0)">{{ $t("btn.view") }}</a>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          property="errorMsg"
          :label="$t('homeTopBar.asyncTask.errorMessage')"
          min-width="200"
        >
        </el-table-column>
      </el-table>

      <div style="text-align: center; margin-top: 10px; margin-bottom: 24px">
        <el-pagination
          background
          layout="prev, pager, next,total"
          :current-page="asyncTaskTablePageObj.currentPage"
          :total="asyncTaskTablePageObj.total"
          :page-size="asyncTaskTablePageObj.pageSize"
          @current-change="asyncTaskTablePageChange"
        ></el-pagination>
      </div>
    </el-dialog>

    <el-dialog
      width="80%"
      top="35vh"
      :close-on-click-modal="false"
      :show-close="false"
      :visible.sync="tipDialogVisible"
    >
      <div style="font-size: 16px; text-align: start">
        {{ $t("share.phone_tip") }}
      </div>

      <el-button
        type="primary"
        size="small"
        style="margin: 24px 0"
        @click="tipDialogVisible = false"
        >{{ $t("share.know") }}</el-button
      >
    </el-dialog>

    <el-dialog
      id="popupDialogVisible_zdh_guide"
      width="25%"
      top="30vh"
      :close-on-click-modal="true"
      :show-close="true"
      :visible.sync="popupDialogVisible"
    >
      <img src="@/assets/img/jiangli.png" alt="" />

      <div
        v-if="reward_b == 'zdh_11'"
        style="font-size: 20px; font-weight: bold; color: black; padding: 20px"
      >
        {{ $t("guide_new.t62") }}
      </div>

      <div v-else>
        <div
          style="
            font-size: 20px;
            font-weight: bold;
            color: black;
            padding: 10px;
          "
        >
          {{ $t("guide_new.t63") }}
          <span style="color: #ffca28">{{
            reward_b > 0 ? reward_b : $route.query.popupDialogVisible
          }}</span>
          {{ $t("guide_new.t64") }}
        </div>
        <div style="padding-bottom: 20px; font-size: 14px; color: black">
          {{ $t("guide_new.t65") }}
          {{ reward_b > 0 ? reward_b : $route.query.popupDialogVisible
          }}{{ $t("guide_new.t66") }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import confetti from "canvas-confetti";
import { ossAuthorization } from "@/utils/oss";
import { limits_of_authority_disable } from "@/utils/limits";
import { removeToken } from "@/utils/auth";
import personalAvatar from "@/components/personal";
import templateLibrary from "@/views/templateLibrary";
import {
  request_allPageProjects,
  request_joinedProjects,
  request_projects_add,
} from "@/network/personal/index.js";
import { setLanguage } from "@/network/user/index.js";
import {
  project_Key_Verification,
  project_Name_Verification,
} from "@/network/fromRules/index.js";

import { getFileArchive } from "@/network/nodeVersion";
import { Message } from "element-ui";
import {
  get_tenant_user_list,
  get_tenant_user_list_nopage,
} from "@/network/user/index.js";
import { unSeen } from "@/network/notification/index.js";
import { ed } from "@/network/guide/index.js";
import { get_obtained_list } from "@/network/third-party/index";
import {
  searchFilesByFuzzyKey,
  searchKnowledgesByFuzzyKey,
  searchNodesByFuzzyKey,
} from "@/network/fileOperation/index.js";
import {
  asyncTaskStatusList,
  asyncTaskTypeList,
  asyncTaskList,
} from "@/network/asyncTask";
import { sessionRead, sessionSave } from "@/utils/session";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Vue from "vue";
import selectIcon from "@/components/selectIcon";
// 新建文件组件
import CreateNewFile from "@/components/createNewFile";
import vmson from "@/utils/vmson";

// import Driver from "@/plugins/driver.js";
// import "@/plugins/driver.css";

import logo from "@/assets/logo.png";
import logo_light from "@/assets/logo_light.png";
import name_logo from "@/assets/name_logo.png";
import name_logo_light from "@/assets/name_logo_light.png";
// 引入引导框样式
// 换肤相关
import ChangeTheme from "@/assets/themes";
import { textRange } from "@/utils/textRange";
import { get_component_license } from "@/network/license";

import { driver } from "driver.js";
import "driver.js/dist/driver.css";
// const driverObj = require("@/plugins/tourDriver"); // 导入driverObj

Vue.prototype.setSessionItem = function (key, newVal) {
  // 创建 StorageEvent 事件
  let newStorageEvent = document.createEvent("StorageEvent");
  const storage = {
    setItem: function (k, val) {
      sessionStorage.setItem(k, val);
      // 初始化 StorageEvent 事件
      newStorageEvent.initStorageEvent(
        "setItem", // 事件别名
        false,
        false,
        k,
        null,
        val,
        null,
        null
      );
      // 派发事件
      window.dispatchEvent(newStorageEvent);
    },
  };
  return storage.setItem(key, newVal);
};
export default {
  inject: ["reload"],
  components: { personalAvatar, templateLibrary, selectIcon, CreateNewFile },

  props: {
    isHome: {
      type: Boolean,
      default: true,
    },
    isPersonal: {
      type: Boolean,
      default: false,
    },
    isNotification: {
      type: Boolean,
      default: false,
    },
    isNodeDetailPage: {
      type: Boolean,
      default: false,
    },
    isWorksapce: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const project_only_n = (rule, value, callback) => {
      project_Name_Verification(this.ruleForm.projectName).then((res) => {
        if (res == true) {
          callback(
            new Error(this.$t("homeTopBar.newProjectForm.message.nameError"))
          );
        } else {
          callback();
        }
      });
    };
    const project_only_k = (rule, value, callback) => {
      let projectKey = this.ruleForm.projectKey.toUpperCase();
      project_Key_Verification(projectKey).then((res) => {
        if (res == true) {
          callback(
            new Error(this.$t("homeTopBar.newProjectForm.message.keyError"))
          );
        } else {
          callback();
        }
      });
    };
    return {
      popupDialogVisible: false,
      reward_b: 0,
      user_create_time: "",
      draverObj: null,
      activeNameSea: "file",
      lastSearchKey: "",
      searchKey: "",
      allSearchResults: {},
      worksapce_choose: "",
      templatePidSelectLoading: false,
      all_project: [],
      is_copy: false,
      tipDialogVisible: false,
      all_user_list: [],
      excelRedisUUID: "",
      headList: [],
      tenantid_list: [],
      demoList: [],
      projectKeyList: [],
      id_list: [],
      type_list: [],
      current_id: "",
      userName: this.$store.getters.name,
      haveUnread: false,
      login_text: this.$t("loading.loading2"),
      loading: true,
      user_id: this.$store.getters.user_info.accountId,
      avatar: this.$store.getters.avatar, //用户没有上传图片的默认头像
      program_name: "", //头部显示项目名称
      program_name_list: [], //项目下拉列表
      program_name_list_origin: [], //项目下拉列表
      avatar_p: [],
      imageUrl:
        "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
      userList: [],
      userListFiltered: [],
      create_project_dialog_visiable: false, //项目列表，创建新项目弹窗
      ruleForm: {
        projectName: "",
        projectKey: "",
        type: "SCRUM",
        avatar: "",
        accountIdList: [],
        templateProjectId: "",
      }, //创建新项目表单
      rules: {
        projectName: [
          {
            required: true,
            message: this.$t("placeholder.notNull"),
            trigger: "blur",
          },
          { validator: project_only_n, trigger: "blur" },
        ],
        projectKey: [
          {
            required: true,
            message: this.$t("placeholder.notNull"),
            trigger: "blur",
          },
          {
            max: 11,
            message: this.$t("placeholder.notLength"),
            trigger: "blur",
          },
          {
            pattern: /^[A-Za-z0-9][A-Za-z0-9_]*[A-Za-z0-9]$/,
            message: this.$t("placeholder.onlyAlgeNum"),
            trigger: "blur",
          },
          { validator: project_only_k, trigger: "blur" },
        ],
      },
      // bug类型文件相关
      test_plan: "",
      test_case: [],
      test_case_list: [],
      test_plan_list: [],
      isLocale: sessionStorage.getItem("isLocale") || "false",
      // 异步任务
      asyncTaskTableLoading: false,
      asyncTaskTableData: [],
      asyncTaskStatus: {},
      asyncTaskType: {},
      asyncTaskTablePageObj: {
        currentPage: 1,
        pageSize: 7,
        tatal: 0,
      },
      asyncTaskDialogVisible: false,
      driver: "",
      isShowToolTip: false,
      payTemplate: [
        {
          payTemplateId: "ASPICE",
          payTemplateName: "PROJECT_TEMPLATE_ASPICE",
          payTemplateLabel: this.$t("certificate.ASPICETitle"),
          payStatus: false,
        },
        {
          payTemplateId: "AVIONICS",
          payTemplateName: "PROJECT_TEMPLATE_AVIONICS",
          payTemplateLabel: this.$t("certificate.AVIONICSTitle"),
          payStatus: false,
        },
      ],
      addPLoading: false,
      roleAllProject: [],
      search_PROJECT_zdh: "",
    };
  },
  computed: {
    ...mapGetters([
      "execution_status",
      "userAccountId",
      "version",
      "testPlan",
      "sprint",
      "baseLine",
      "theme",
      "user_list",
    ]),
    logoUrl() {
      let url = logo;
      const map = {
        light: logo_light,
        dark: logo,
      };
      if (this.theme && map[this.theme]) {
        url = map[this.theme];
      }
      return url;
    },
    nameLogoUrl() {
      let url = name_logo;
      const map = {
        light: name_logo_light,
        dark: name_logo,
      };
      if (this.theme && map[this.theme]) {
        url = map[this.theme];
      }
      return url;
    },
    notice_num() {
      return this.$store.getters.updateNum;
    },
    has_driver() {
      return this.driver.isActivated;
    },
  },
  watch: {
    search_PROJECT_zdh() {
      if (this.search_PROJECT_zdh.length > 0) {
        let b = this.search_PROJECT_zdh.toLowerCase();
        let a = this.program_name_list_origin.filter((programName) => {
          return programName.toLowerCase().includes(b);
        });
        let c = this.projectKeyList.filter((programName) => {
          return programName.toLowerCase().includes(b);
        });
        let s = new Set(a);
        for (let i of c) {
          s.add(this.program_name_list_origin[this.projectKeyList.indexOf(i)]);
        }
        this.program_name_list = [...s];
      } else {
        this.program_name_list = this.program_name_list_origin;
      }
    },
    worksapce_choose() {
      this.$router.push({ path: `/${this.worksapce_choose}/home/my_file` });
    },
  },
  created() {
    request_joinedProjects().then((res) => {
      this.$store.commit("set_joined_projects", res);
      this.creat_after();
    });
    const self = this;
    // 监听首页指引
    window.addEventListener("message", (e) => {
      if (e.data) {
        if (e.data == self.$t("addJs.close")) {
          sessionStorage.setItem("isHomeTip", "888");
          localStorage.setItem("isHomeTip", "888");
          sessionStorage.setItem("wordviewtip", "true");
          localStorage.setItem("wordviewtip", "true");
        }
      }
    });
  },
  mounted() {
    if (this.$route.query.popupDialogVisible) {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
        zIndex: 9999999999,
      });
      this.popupDialogVisible = true;
    }

    if (this.$route.query.tool_tip == "global_tip_1") {
      this.driverObj = driver({
        showProgress: true,
        allowClose: false,
        steps: [
          {
            element: "#zdh_guide_tip1_btn1",
            popover: {
              title: this.$t("guide_new.t15"),
              description: this.$t("guide_new.t16"),
              side: "left",
              align: "start",
              showButtons: [],
            },
          },
          {
            element: "#zdh_guide_tip1_dig1 > *:first-child",
            popover: {
              title: this.$t("guide_new.t15"),
              description: this.$t("guide_new.t17"),
              side: "right",
              align: "start",
              showButtons: [],
            },
          },
        ],
      });
    }
    if (this.$route.query.tool_tip == "global_tip_2") {
      this.driverObj = driver({
        nextBtnText: this.$t("guide_new.t18"),
        popoverClass: "customer-popover",
        doneBtnText: this.$t("guide_new.t19"),
        showProgress: true,
        allowClose: false,
        onDestroyed: () => {
          ed({ guideType: "MINDMAP_OPERATION" }).then((b) => {
            if (!b.success) {
              return;
            }
            this.reward_b = b.reward;
            confetti({
              particleCount: 100,
              spread: 70,
              origin: { y: 0.6 },
              zIndex: 9999999999,
            });
            this.popupDialogVisible = true;
          });
        },
        steps: [
          {
            element: ".fuchuang_new_zdh",
            popover: {
              title: this.$t("guide_new.t20"),
              description: this.$t("guide_new.t21"),
              side: "left",
              align: "start",
              showButtons: ["next"],
            },
          },
          {
            element: ".root",
            popover: {
              title: this.$t("guide_new.t22"),
              description: this.$t("guide_new.t23"),
              side: "right",
              align: "start",
              showButtons: ["next"],
            },
          },
          {
            element: ".jsmind-inner",
            popover: {
              title: this.$t("guide_new.t24"),
              description: this.$t("guide_new.t25"),
              side: "right",
              align: "end",
              showButtons: ["next"],
            },
          },
        ],
      });
      this.driverObj.drive();
    }
    if (this.$route.query.tool_tip == "global_tip_3") {
      this.driverObj = driver({
        showProgress: true,
        allowClose: false,
        steps: [
          {
            element: "#zdh_guide_tip1_btn1",
            popover: {
              title: this.$t("guide_new.t26"),
              description: this.$t("guide_new.t27"),
              side: "left",
              align: "start",
              showButtons: [],
            },
          },
          {
            element: "#zdh_guide_tip3_btn2",
            popover: {
              title: this.$t("guide_new.t28"),
              description: this.$t("guide_new.t29"),
              side: "right",
              align: "start",
              showButtons: [],
            },
          },
          {
            element: ".dialog-content-left-bottom > *:first-child",
            popover: {
              title: this.$t("guide_new.t28"),
              description: this.$t("guide_new.t30"),
              side: "left",
              align: "start",
              showButtons: [],
            },
          },

          {
            element: ".dialog-content-right",
            popover: {
              title: this.$t("guide_new.t28"),
              description: this.$t("guide_new.t31"),
              side: "left",
              align: "start",
              showButtons: [],
            },
          },
          {
            element: "#zdh_guide_tip3_dig3 > *:first-child",
            popover: {
              title: this.$t("guide_new.t28"),
              description: this.$t("guide_new.t32"),
              side: "left",
              align: "start",
              showButtons: [],
            },
          },
          {
            element: "#zdh_guide_tip1_dig1 > *:first-child",
            popover: {
              title: this.$t("guide_new.t28"),
              description: this.$t("guide_new.t33"),
              side: "right",
              align: "start",
              showButtons: [],
            },
          },
        ],
      });
    }
    if (
      this.$route.query.tool_tip == "global_tip_4" &&
      this.$route.path.indexOf("myMind") > -1
    ) {
      this.driverObj = driver({
        showProgress: true,
        popoverClass: "customer-popover",
        allowClose: false,
        nextBtnText: this.$t("guide_new.t18"),
        steps: [
          {
            element: "#four_view_switch",
            popover: {
              title: this.$t("guide_new.t34"),
              description: this.$t("guide_new.t35"),
              side: "left",
              align: "start",
              showButtons: ["next"],
            },
          },
          {
            element: "#four_view_switch > *:nth-child(2)",
            popover: {
              title: this.$t("guide_new.t36"),
              description: this.$t("guide_new.t37"),
              side: "right",
              align: "start",
              showButtons: [],
            },
          },
          {
            element: "#zdh_guide_expand_all_tip4",
            popover: {
              title: this.$t("guide_new.t38"),
              description: this.$t("guide_new.t39"),
              side: "right",
              align: "start",
              showButtons: ["next"],
            },
          },
          {
            element: ".icon-drawer",
            popover: {
              title: this.$t("guide_new.t40"),
              description: this.$t("guide_new.t40"),
              side: "right",
              align: "start",
              showButtons: [],
            },
          },
        ],
      });
      this.driverObj.drive();
    }
    if (this.$route.query.tool_tip == "global_tip_5") {
      this.driverObj = driver({
        showProgress: true,
        allowClose: false,
        popoverClass: "customer-popover",
        nextBtnText: this.$t("guide_new.t18"),
        doneBtnText: this.$t("guide_new.t19"),
        onDestroyed: () => {
          ed({ guideType: "NODE_DETAIL" }).then((b) => {
            if (!b.success) {
              return;
            }
            let new_route = this.$router.resolve({
              path: `/${this.get_pid()}/home/guide`,
              query: { popupDialogVisible: b.reward },
            });
            window.open(new_route.href, "_blank");
          });
        },
        steps: [
          {
            element: "#zdh_guide_golbal_tip5_1",
            popover: {
              title: this.$t("guide_new.t42"),
              description: this.$t("guide_new.t43"),
              side: "left",
              align: "start",
              showButtons: ["next"],
            },
          },
          {
            element: "#zdh_guide_golbal_tip5_2",
            popover: {
              title: this.$t("guide_new.t44"),
              description: this.$t("guide_new.t45"),
              side: "right",
              align: "start",
              showButtons: ["next"],
            },
          },
          {
            element: "#zdh_guide_golbal_tip5_3",
            popover: {
              title: this.$t("guide_new.t46"),
              description: this.$t("guide_new.t47"),
              side: "right",
              align: "start",
              showButtons: ["next"],
            },
          },
          {
            element: "#zdh_guide_golbal_tip5_4",
            popover: {
              title: this.$t("guide_new.t48"),
              description: this.$t("guide_new.t49"),
              side: "right",
              align: "start",
              showButtons: ["next"],
            },
          },
          {
            element: "#zdh_guide_golbal_tip5_5",
            popover: {
              title: this.$t("guide_new.t50"),
              description: this.$t("guide_new.t51"),
              side: "right",
              align: "start",
              showButtons: ["next"],
            },
          },
          {
            element: "#zdh_guide_golbal_tip5_6",
            popover: {
              title: this.$t("guide_new.t52"),
              description: this.$t("guide_new.t53"),
              side: "right",
              align: "start",
              showButtons: ["next"],
            },
          },
          {
            element: "#zdh_guide_golbal_tip5_7",
            popover: {
              title: this.$t("guide_new.t54"),
              description: this.$t("guide_new.t55"),

              side: "right",
              align: "start",
              showButtons: ["next"],
            },
          },
        ],
      });
      this.driverObj.drive();
    }
    if (this.$route.query.tool_tip == "global_tip_8") {
      this.driverObj = driver({
        showProgress: true,
        allowClose: false,
        steps: [
          {
            element: "#zdh_guide_tip1_btn1",
            popover: {
              title: "项目主页面新建文件",
              description: "点击新建按钮，创建一个全新文件",
              side: "left",
              align: "start",
              showButtons: [],
            },
          },
        ],
      });
    }
    if (this.$route.query.tool_tip == "global_tip_9") {
      this.driverObj = driver({
        nextBtnText: this.$t("guide_new.t18"),
        popoverClass: "customer-popover",
        showProgress: true,
        doneBtnText: this.$t("guide_new.t19"),
        allowClose: false,
        onDestroyed: () => {
          ed({ guideType: "QUERY_CONDITION" }).then((b) => {
            if (!b.success) {
              return;
            }
            let new_route = this.$router.resolve({
              path: `/${this.get_pid()}/home/guide`,
              query: { popupDialogVisible: b.reward },
            });
            window.open(new_route.href, "_blank");
          });
        },
        steps: [
          {
            element: ".header_filter",
            popover: {
              title: this.$t("guide_new.t56"),
              description: this.$t("guide_new.t57"),
              side: "bottom",
              align: "end",
              showButtons: ["next"],
            },
          },
          {
            element: "#zdh_guide_golbal_tip9_btn1",
            popover: {
              title: this.$t("guide_new.t58"),
              description: this.$t("guide_new.t59"),
              side: "right",
              align: "start",
              showButtons: ["next"],
            },
          },
          {
            element: ".filter_wrap",
            popover: {
              title: this.$t("guide_new.t60"),
              description: this.$t("guide_new.t61"),

              side: "right",
              align: "start",
              showButtons: ["next"],
            },
          },
        ],
      });
      this.$nextTick(() => {
        setTimeout(() => {
          this.driverObj.drive();
        }, 1000);
      });
    }
    vmson.$on("global_tip", (val) => {
      console.log(val);
      if (val.split("@")[0] == "4") {
        if (val.split("@")[1] == "next") {
          setTimeout(() => {
            this.driverObj.moveNext();
          }, 2000);
        }
        if (val.split("@")[1] == "word") {
          setTimeout(() => {
            this.driverObj.drive(1);
          }, 2000);
        }
        if (val.split("@")[1] == "end") {
          this.driverObj.destroy();
        }
      }
      if (val.split("@")[0] == "1" || val.split("@")[0] == "3") {
        if (val.split("@")[1] == "next") {
          this.driverObj.moveNext();
        }
        if (val.split("@")[1] == "fast") {
          this.driverObj.moveTo(5);
          document.querySelector(".driver-overlay").style.zIndex = 999;
        }
        if (val.split("@")[1] == "end") {
          this.driverObj.destroy();

          // 关闭弹窗（假设3秒后关闭）
          // setTimeout(() => {
          //   popup.style.display = "none";
          // }, 3000);
        }
      }
    });
    vmson.$on("fireword_and_reward", (val) => {
      this.reward_b = val;
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
        zIndex: 9999999999,
      });
      this.popupDialogVisible = true;
    });
    // 获取当前账号所在的所有项目
    this.roleAllProject = this.$store.getters.t_joined_projects;
    let system = {
      win: false,
      mac: false,
      xll: false,
    };
    let p = navigator.platform;
    system.win = p.indexOf("Win") == 0;
    system.mac = p.indexOf("Mac") == 0;
    system.x11 = p == "X11" || p.indexOf("Linux") == 0;
    if (!(system.win || system.mac || system.xll)) {
      this.tipDialogVisible = true;
    }
    window.addEventListener("setItem", () => {
      this.isLocale = sessionStorage.getItem("isLocale");
    });
    let loadingTime = setInterval(() => {
      if (this.program_name_list_origin.length > 0) {
        this.loading = false;
        clearTimeout(loadingTime);
      }
    }, 50);

    // 限制首页引导弹窗组件部分只在首页实现
    // if (this.$router.history.current.fullPath == "/home") {
    //   // 用户引导提示
    //   if (
    //     sessionStorage.getItem("isHomeTip") ||
    //     localStorage.getItem("isHomeTip")
    //   ) {
    //   } else {
    //     setTimeout(() => {
    //       if (window.Driver) {
    //         this.driver = new window.Driver();
    //       } else {
    //         this.driver = new Driver();
    //       }

    //       let steps = [];
    //       steps.push({
    //         element: "#right-header-img",
    //         popover: {
    //           title: this.$t("canvas.fileDropDown.deleteForm.title"),
    //           description: this.$t("file.dialog.invite"),
    //           position: "bottom-right",
    //           className: "isHomeTip",
    //           closeBtnText: this.$t("btn.jumpBtn"),
    //           foreverBtnText: this.$t("btn.noLongerDisplayed"),
    //           nextBtnText: this.$t("btn.next"), // Next button text for this step 下一步按钮的文字
    //           prevBtnText: this.$t("btn.prev"), // Previous button text for this step 上一步按钮文字
    //         },
    //       });
    //       steps.push({
    //         element: "#userGuide",
    //         popover: {
    //           title: this.$t("canvas.fileDropDown.deleteForm.title"),
    //           description: this.$t("canvas.tip8"),
    //           position: "top-right",
    //           className: "istip",
    //           closeBtnText: this.$t("btn.jumpBtn"),
    //           foreverBtnText: this.$t("btn.noLongerDisplayed"),
    //           nextBtnText: this.$t("btn.next"), // Next button text for this step 下一步按钮的文字
    //           prevBtnText: this.$t("btn.prev"), // Previous button text for this step 上一步按钮文字
    //         },
    //       });

    //       this.driver.defineSteps(steps);
    //       this.driver.start();
    //     }, 2000);
    //   }
    // }
    this.$emit("child", this.$refs.header.clientHeight);
    // 换肤相关
    const theme = localStorage.getItem("_theme");
    // 加载主题 sysSkinColor
    this.changeTheme(theme);
    this.getPayInfo();
    if (this.$route.query.show_task) {
      this.goAsyncTask();
    }
  },
  beforeDestroy() {
    // 移除对 sessionStorage 的监听
    window.removeEventListener("setItem", () => {});
  },
  methods: {
    ...mapMutations(["setShowDfmea", "setShowTARA", "setShowHARA"]),
    getFileArchive(query) {
      const params = {
        projectId: query.projectId,
        fileKey: query.fileKey,
      };
      getFileArchive(params).then((res) => {
        const projectId = res[0].projectId;
        const fileKey = res[0].fileKey;
        const routeData = this.$router.resolve({
          path: `/${projectId}/myMind/${fileKey}?showFileVersion=true`,
        });
        window.open(routeData.href, "_blank");
      });
    },
    showToptitle(title) {
      return title.replace(/\n/g, "");
    },
    findSurroundingChars(inputString, targetCharacter) {
      const result = [];
      const segments = inputString.split(targetCharacter);
      for (let i = 0; i < segments.length; i++) {
        if (i > 0 && result.length < 3) {
          const prevSegment = segments[i - 1];
          const currentSegment = segments[i];
          const surroundingChars =
            prevSegment.slice(-3) +
            targetCharacter +
            currentSegment.slice(0, 3);
          result.push(surroundingChars);
        }
      }
      return result;
    },
    setShowtext(text) {
      return this.findSurroundingChars(text.replace(/\n/g, ""), this.searchKey);
    },
    highlightText(text) {
      const targetString = this.searchKey; // 要高亮显示的目标字符串
      const regex = new RegExp(targetString, "g");
      const highlightedText = text.replace(
        regex,
        `<mark style="background-color: rgba(42, 130, 228, 0.13)">${targetString}</mark>`
      );
      return highlightedText;
    },
    // 换肤
    changeTheme(theme) {
      ChangeTheme.mergeCss(theme);
      this.SET_THEME(theme);
    },
    // 下载pdf
    downloadPdf(url) {
      window.open(url, "blank");
    },
    search_PROJECT_zdh_add() {
      this.ruleForm = {
        projectName: "",
        projectKey: "",
        type: "SCRUM",
        avatar: "",
        accountIdList: [],
        templateProjectId: "",
      };
      this.create_project_dialog_visiable = true;
    },
    search_PROJECT_zdh_js() {
      console.log(999);
    },
    // 跳转文件
    jumpToFile(row) {
      //跳转到思维导图
      const { href } = this.$router.resolve({
        path: `/${row.projectId}/myMind/${row.output.fileKey}`,
      });
      window.open(href, "_blank");
    },
    // 刷新异步任务列表
    refreshAsyncTask() {
      this.asyncTaskList();
    },
    // 获取异步任务列表
    async asyncTaskList() {
      this.asyncTaskTableLoading = true;
      this.asyncTaskTableData = [];
      const data = await asyncTaskList({
        pageSize: this.asyncTaskTablePageObj.pageSize,
        pageNumber: this.asyncTaskTablePageObj.currentPage - 1,
      });
      this.asyncTaskTableData = data.content;
      // 获取文生产品任务对应的项目名
      if (this.asyncTaskTableData.length > 0) {
        this.asyncTaskTableData.forEach((item, index) => {
          if (item.asyncTaskType == "MGPT_CREATE_PRODUCT" && item.projectId) {
            let namesArr = this.roleAllProject.filter((fitem) => {
              return fitem.projectId == item.projectId;
            });
            console.log(namesArr.map((i) => i.projectName).join(","));
            this.asyncTaskTableData[index]["projectName"] = namesArr
              .map((i) => i.projectName)
              .join(",");
          }
        });
      }
      this.asyncTaskTablePageObj.total = data.totalElements;
      this.asyncTaskDialogVisible = true;
      this.asyncTaskTableLoading = false;
    },
    // 异步任务分页相关
    asyncTaskTablePageChange(current) {
      this.asyncTaskTablePageObj.currentPage = current;
      this.asyncTaskList();
    },
    /////
    creat_after() {
      this.templatePidSelectLoading = true;
      this.setSessionItem("isLocale", "true");
      if (!this.$store.getters.t_joined_projects.length) {
        removeToken();
        this.$router.push("/login/login_password");
      }
      if ("projectId" in this.$route.params) {
        let flag = 0;
        this.$store.getters.t_joined_projects.forEach((e) => {
          this.program_name_list.push(e.projectName);
          this.program_name_list_origin.push(e.projectName);
          this.avatar_p.push(e.avatar);
          this.id_list.push(e.projectId); //项目ID
          this.type_list.push(e.type); //项目类型
          this.tenantid_list.push(e.tenantId);
          this.demoList.push(e.isDemo);
          this.projectKeyList.push(e.projectKey);
          // 针对是否展示dfmea
          if (e.projectId == this.get_pid()) {
            this.set_release_obj(this.get_pid());
            flag = 1;
            this.program_name = e.projectName;
            this.$nextTick(() => {
              if (this.$route.query.tool_tip == "global_tip_1") {
                this.driverObj.drive();
              }
              if (this.$route.query.tool_tip == "global_tip_3") {
                this.driverObj.drive();
              }
            });
            this.$store.commit("SET_PROJECTNAME", e.projectName);
            this.$store.dispatch("jobAuthorities", e.projectId);
            this.$store.commit("SET_PROJECT_TYPE", e.type);
            this.$store.commit("SET_TENANTID", e.tenantId);
            if (e.isDemo && e.projectKey === "ATP") {
              this.setShowDfmea(true);
              this.setShowTARA(true);
              this.setShowHARA(true);
            } else {
              this.setShowDfmea(false);
              this.setShowTARA(false);
              this.setShowHARA(false);
            }
          }
        });
        if (flag < 1) {
          this.$message.error(this.$t("fileType.permission.table.label2"));
          this.$router.push({ path: `/home` });
          return false;
        }
      } else {
        this.$store.getters.t_joined_projects.forEach((e) => {
          this.program_name_list_origin.push(e.projectName);
          this.program_name_list.push(e.projectName);
          this.avatar_p.push(e.avatar);
          this.id_list.push(e.projectId); //项目ID
          this.type_list.push(e.type); //项目类型
          this.tenantid_list.push(e.tenantId);
        });
      }
      unSeen().then((res) => {
        this.$store.commit("SET_NOTICE_NUM", res.unSeenNumber);
      });
      this.userName = this.$store.getters.name;
      const params = {
        pageNumber: 0,
        pageSize: 1000000,
        fuzzyName: "",
      };
      request_allPageProjects(params).then((res) => {
        this.templatePidSelectLoading = false;
        this.all_project = res.content;
      });

      this.get_testplan_list();
      /**获取用户列表 */
      get_tenant_user_list().then((res) => {
        this.userList = res.content;
        this.userListFiltered = this.userList.filter(
          (user) => user.accountId !== this.$store.state.user.userAccountId
        );
      });
      // 获取已安装的第三方应用列表
      get_obtained_list().then((res) => {
        this.$store.commit("SET_THIRD_APPS", res);
      });
      this.setUser();
      this.setuserGroup();
      this.get_allProjectMember();
      this.setAllFileType();
    },
    cut(str) {
      const chars =
        "ABCDEFGHIJKMNPQRSTWXYZabcdefhijkmnprstwxyz12345678oOLl9gq,Vv,Uu";
      let length = 0;
      for (const char of str) {
        if (chars.includes(char)) {
          length += 1;
        } else {
          length += 2;
        }
      }
      if (!str) {
        return "";
      }
      if (length > 50) {
        return str.slice(0, 49) + "...";
      } else {
        return str;
      }
    },
    click_logo() {
      if (this.$route.params.projectId) {
        this.$router.push({
          path: `/${this.$route.params.projectId}/home/my_file`,
        });
        sessionSave("p_id", this.$route.params.projectId);
      } else if (sessionRead("p_id")) {
        this.$router.push({ path: `/${sessionRead("p_id")}/home/my_file` });
      } else {
        sessionSave("p_id", this.$store.getters.t_joined_projects[0].projectId);
        this.$router.push({
          path: `/${this.$store.getters.t_joined_projects[0].projectId}/home/my_file`,
        });
      }
    },
    // 修改搜索
    get_data_to_ipt() {
      this.searchKey = this.lastSearchKey || "";
      this.$refs.sea_ipt.focus();
    },
    loseBlur() {
      this.lastSearchKey = this.searchKey;
    },
    openNew(val) {
      const idx = val.indexOf(":");
      const idx2 = val.lastIndexOf(":");
      const value = val.slice(0, idx);
      const type = val.slice(idx + 1, idx2);
      const projectId = val.slice(idx2 + 1, val.length);
      if (type === "file") {
        const { href } = this.$router.resolve({
          path: `/${projectId}/myMind/${value}`,
        });
        window.open(href, "_blank");
      } else if (type === "node") {
        const { href } = this.$router.resolve({
          path: `/${projectId}/nodes/key/${value}`,
        });
        window.open(href, "_blank");
      } else if (type === "knowledge") {
        const { href } = this.$router.resolve({
          path: `/${projectId}/knowledge?documentId=${value}`,
        });
        window.open(href, "_blank");
      }
      this.searchKey = "";
      // this.$refs.select.focus();
    },
    // 模糊搜索方法 函数柯里化实现
    searchByTypeAndFuzzyKey(type) {
      return async function (val) {
        switch (type) {
          case "node":
            return await searchNodesByFuzzyKey(val);
          case "knowledge":
            return await searchKnowledgesByFuzzyKey(val);
          default:
            return await searchFilesByFuzzyKey(val);
        }
      };
    },
    async searchFuzzy() {
      if (this.activeNameSea === "node") {
        this.allSearchResults.nodes = await this.searchByTypeAndFuzzyKey(
          "node"
        )(this.searchKey);
      } else if (this.activeNameSea === "knowledge") {
        this.allSearchResults.knowledges = await this.searchByTypeAndFuzzyKey(
          "knowledge"
        )(this.searchKey);
      } else {
        this.allSearchResults.files = await this.searchByTypeAndFuzzyKey("")(
          this.searchKey
        );
      }
      this.$forceUpdate();
    },
    // 切换tab标签触发方法
    handleClickSea(val) {
      this.activeNameSea = val.name;
    },
    ...mapActions([
      "setTestPlan",
      "setVersion",
      "setSprint",
      "setBaseLine",
      "setUser",
      "setuserGroup",
      "setExecutionStatus",
      "setFileType",
      "setStatus",
      "setProjectUserList",
      "setAllFileType",
    ]),
    ...mapMutations([
      "SET_THEME", //换肤相关
    ]),
    limits_of_authority_disable,
    // 存入项目相关数据
    set_release_obj(pid) {
      let i = pid ? pid : this.get_pid();
      this.setTestPlan(i);
      this.setVersion(i);
      this.setSprint(i);
      this.setBaseLine(i);
      this.setExecutionStatus(i);
      this.setFileType(i);
      this.setProjectUserList(i);
      this.setStatus(i);
    },
    goToQuestion() {
      window.open(this.$t("UserGuide"), "_blank");
    },
    get_allProjectMember() {
      //设置项目所有用户
      this.all_user_list = [];
      Object.values(this.all_user_list).forEach((ele) => {
        let obj = {
          label: ele.nickname,
          value: ele.accountId,
          userAccountId: ele.accountId,
          avatar: ele.avatar,
          deleted: ele.deleted,
        };
        this.all_user_list.push(obj);
      });
    },
    add_window() {
      this.$emit("dialoghover", true);
    },
    programClose() {},
    handleClick(command) {
      if (command === "logout") {
        this.$store.dispatch("logout").then(() => {
          this.$router.replace("/login/");
        });
      } else if (command === "profile") {
        this.$router.push("/profile/");
      }
    },
    async worksapcehandleClick(command) {
      if (command === "workbench") {
        this.$router.push("/home");
      } else if (command === "asyncTask") {
        const status = await asyncTaskStatusList();
        const type = await asyncTaskTypeList();
        status.forEach((item) => {
          this.asyncTaskStatus[item.status] = item.description;
        });
        type.forEach((item) => {
          this.asyncTaskType[item.taskType] = item.description;
        });
        this.asyncTaskList();
      } else if (command === "question") {
        this.goToQuestion();
      }
    },
    goWorkbench() {
      this.$router.push("/home");
    },
    async goAsyncTask() {
      const status = await asyncTaskStatusList();
      const type = await asyncTaskTypeList();
      status.forEach((item) => {
        this.asyncTaskStatus[item.status] = item.description;
      });
      type.forEach((item) => {
        this.asyncTaskType[item.taskType] = item.description;
      });
      this.asyncTaskList();
    },
    handleClickLocale(command) {
      const map = {
        zh: "zh-CN",
        en: "en-US",
      };
      localStorage.setItem("lang", command);
      setLanguage({
        language: map[command],
      }).then((res) => {
        window.location.reload();
      });
    },
    handleClickUser() {
      this.$router.push("/profile/");
    },
    programClick(item) {
      if (item == "program_add_zdh") {
        return;
      }
      //项目下拉列表点击事件
      if (item == "program_add") {
        this.ruleForm = {
          projectName: "",
          projectKey: "",
          type: "SCRUM",
          avatar: "",
          accountIdList: [],
          templateProjectId: "",
        };
        this.create_project_dialog_visiable = true;
      } else {
        for (let i = 0; i < this.id_list.length; i++) {
          if (this.program_name_list_origin[i] == item) {
            //此处更新全局项目相关属性
            sessionSave("p_id", this.id_list[i]);
            this.$store.dispatch("jobAuthorities", this.id_list[i]);
            this.get_allProjectMember(); //设置项目所有用户
            this.$store.commit("SET_PROJECT_TYPE", this.type_list[i]);
            this.$store.commit("SET_PROJECTNAME", item); //设置项目name
            this.current_id = this.id_list[i];
            this.program_name = item;
            if (this.demoList[i] && this.projectKeyList[i] === "ATP") {
              this.setShowTARA(true);
              this.setShowHARA(true);
              this.setShowDfmea(true);
            } else {
              this.setShowTARA(false);
              this.setShowHARA(false);
              this.setShowDfmea(false);
            }
            break;
          }
        }
        this.set_release_obj(this.current_id);
        this.$refs.zdh_top_hometopbar.$data.showPopper = false;
        this.search_PROJECT_zdh = "";
        this.$router.push({ path: `/${this.current_id}/home/my_file` });
      }
    },
    /**上传头像 */
    handleAvatarSuccess(res, file) {
      this.imageUrl = res;
      this.ruleForm.avatar = res;
    },
    handleFileError(err) {
      console.log(err);
    },
    beforeAvatarUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.$message.error(
          this.$t("homeTopBar.newProjectForm.message.avatarError")
        );
      }
      return isLt1M;
    },
    //参数必须是param，才能获取到内容
    async uploadFile(param) {
      const loading = this.$loading({
        lock: true,
        text: this.$t("loading.loading5"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // 获取上传的文件名
      let file = param.file;
      //发送请求的参数格式为FormData
      await ossAuthorization.call(this);
      this.client
        .put(
          `${this.$store.state.project.tenantId}/user_avatar/${file.name}`,
          file
        )
        .then((ress) => {
          loading.close();
          this.imageUrl = ress.url;
          this.ruleForm.avatar = ress.url;
        })
        .error((r) => {
          loading.close();
          this.$message.error(
            this.$t("homeTopBar.newProjectForm.message.uploadError")
          );
        });
    },
    /**添加项目 */
    addproject(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addPLoading = true;
          this.ruleForm.projectKey = this.ruleForm.projectKey.toUpperCase();
          let da = JSON.parse(JSON.stringify(this.ruleForm));
          if (this.ruleForm.templateProjectId) {
            delete da["type"];
          }
          if (da.templateProjectId == "ASPICE") {
            (da.templateProjectId = ""), (da["payProjectTemplate"] = "ASPICE");
          }
          if (da.templateProjectId == "AVIONICS") {
            (da.templateProjectId = ""),
              (da["payProjectTemplate"] = "AVIONICS");
          }
          request_projects_add(da).then((res) => {
            Message({
              message: this.$t("homeTopBar.newProjectForm.message.addSuccess"),
              type: "success",
              duration: 5 * 1000,
            });
            this.create_project_dialog_visiable = false;
            request_joinedProjects().then((res) => {
              this.$store.commit("set_joined_projects", res);
              this.$router.go(0);
              this.addPLoading = false;
              // let tempProjId = "";
              // res.forEach((item) => {
              //   if (item.projectName == da.projectName && item.projectKey == da.projectKey) {
              //     tempProjId = item.projectId;
              //   }
              // });
              // if (tempProjId != "") {
              //   this.$router.push({ path: `/${tempProjId}/home/my_file` });
              //   this.$router.go(0);
              // }
            });
          });
        }
      });
    },
    get_testplan_list() {
      if (this.$route.params.projectId) {
        this.test_plan_list = Object.values(this.testPlan);
        this.test_plan_list.forEach((item) => {
          if (item.status == "NOT_STARTED") {
            item.name_display =
              `${item.name} (` +
              this.$t("canvas.testPlan.status.notStarted") +
              ")";
          } else if (item.status == "IN_PROGRESS") {
            item.name_display =
              `${item.name} (` +
              this.$t("canvas.testPlan.status.inProgress") +
              ")";
          } else if (item.status == "COMPLETE") {
            item.name_display =
              `${item.name} (` +
              this.$t("canvas.testPlan.status.completed") +
              ")";
          }
        });
      }
    },

    collapse() {
      this.$emit("collapse");
    },
    ifShowToolTip(event) {
      this.isShowToolTip = textRange(event.target);
    },
    getPayInfo() {
      // 获取收费模板信息
      get_component_license().then((res) => {
        for (let i = 0; i < this.payTemplate.length; i++) {
          for (let j = 0; j < res.length; j++) {
            if (this.payTemplate[i].payTemplateName == res[j].feature) {
              this.payTemplate[i].payStatus = true;
              break;
            }
          }
        }
      });
    },
    hanlderClickPayTemplate(status, id) {
      if (!status) {
        this.$message({
          duration: 0,
          showClose: true,
          dangerouslyUseHTMLString: true,
          message: `<span>${this.$t("certificate.Please")}<a href="${
            window.location.origin
          }/profile/certificate_management?target=${id}" target="_blank" style="color: blue">${this.$t(
            "certificate.CertificateManagement"
          )}</a>${this.$t("certificate.InstallComponents")}</span>`,
        });
      }
    },
    handlerPidSelect(value) {
      this.payTemplate.forEach((item) => {
        if (item.payTemplateId == value) {
          if (!item.payStatus) {
            this.ruleForm.templateProjectId = "";
            return;
          }
        }
      });
    },
    goToProject(proid) {
      const { href } = this.$router.resolve({
        path: `/${proid}/home/my_file`,
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.status_tag {
  width: 100%;
  display: inline-block;
  overflow: hidden;
}
.status_ASYNC_TASK_INITIALIZING {
  background-color: rgba(166, 166, 166, 1);
  border: 1px solid rgba(166, 166, 166, 1);
  color: white;
}
.status_ASYNC_TASK_COMPLETED {
  color: white;
  background-color: rgba(67, 207, 124, 1);
  border: 1px solid rgba(67, 207, 124, 1);
}
.status_ASYNC_TASK_IN_PROGRESS {
  color: white;
  background-color: rgba(42, 130, 228, 1);
  border: 1px solid rgba(42, 130, 228, 1);
}
.status_ASYNC_TASK_ERROR {
  color: white;
  background-color: #f56c6c;
  border: 1px solid #f56c6c;
}
.status {
  width: 100%;
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.grid-excel {
  display: grid;
  grid-template-columns: 30% 70%;
  // grid-column-gap: 10%;
  align-items: center;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.avatar-and-name {
  display: flex;
  align-items: center;
  height: 100%;
}

.wrapper {
  background: linear-gradient(
    90deg,
    rgba(0, 76, 170, 1) 34%,
    rgba(3, 122, 188, 1) 100%
  );
  height: 100%;
  width: 100%;
}

.container {
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
}

/**项目列表图片 */
.img_size {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.img_size02 {
  transform: scale(0.9);
  margin-left: -5px;
  margin-right: 5px;
}

.list_flex {
  max-width: 500px;
  height: 45px;
  display: flex;
  align-items: center;
}

.container .topbar-wrap {
  height: 100%;
  line-height: 100%;
  padding: 0px;
  display: flex;
  justify-content: space-between;
}

.porject_name {
  margin-left: 10px;
  text-align: left;
  // 单行显示省略号
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.topbar-logo {
  padding-left: 10px;
  padding-right: 17px;
  padding-top: 7px;
  width: 75.4px;
  height: 55.9px;
  &:hover {
    cursor: pointer;
  }
}

.topbar-logo img {
  max-height: 100%;
  max-width: 100%;
}

.left-header {
  display: flex;
}

.right-header {
  display: flex;
  align-items: center;
  // margin-right: 20px;
}

.head-header {
  // width: 240px;
  display: flex;
  align-items: center;
  color: #fff;

  &:hover {
    cursor: pointer;
  }
}

.fas {
  color: rgba(196, 196, 196, 1);
}
mark {
  background-color: yellow; /* 设置高亮的背景色 */
  color: black; /* 设置文本颜色，根据需要进行调整 */
}
.fas:hover {
  color: #60a3d9;
}

.head-program {
  display: flex;
  margin-left: 20px;

  .el-dropdown {
    display: flex;
    align-items: center;
  }

  h2 {
    margin: 0;
  }
}

.head-choose-project {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 45px;
}

.head-create-file {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 45px;

  &__btn {
    background-color: #fff;
    border-radius: 6px;
    border: none;
    color: rgba(51, 102, 144, 1);
    &:hover {
      cursor: pointer;
    }
  }
}

/* 新建文件弹窗样式 */
.createpro {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
}

.createpro-header {
  color: rgba(49, 100, 143, 1);
  font-family: "思源黑体";
  font-size: 16px;
  margin-top: -30px;

  .is-active {
    color: #30648f;
  }
  ::v-deep .el-select {
    .tags-select-input {
      height: 40px;
      .el-select__tags {
        height: 40px;
        white-space: nowrap;
        overflow: hidden;
        flex-wrap: wrap;
      }
    }

    .el-select__tags-text {
      display: inline-block;
      max-width: 160px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .el-tag__close.el-icon-close {
      top: -7px;
    }
  }
}
.createpro .el-form {
  margin-top: 20px;
}

.createpro-select {
  width: 100%;
}

.dialog-footer {
  display: flex;
  justify-content: right;
}

.dialog-footer .button-confirm {
  background-color: rgba(1, 76, 170, 1);
  color: #f8fafc;
}

.dialog-footer .button-confirm:hover {
  background-color: #5596cb;
}

.head-img:hover {
  cursor: pointer;
}

::v-deep .create_project {
  .el-dialog__header {
    padding-right: 40px;
    padding-left: 40px;
  }

  .el-dialog__body {
    padding: 30px 40px;

    .avatar {
      .el-form-item__content {
        display: flex;
        align-items: center;
      }

      .upload_avatar {
        margin-left: 16px;
        font-size: 12px;
        color: #30648f;
      }
    }
  }

  .input_box {
    position: relative;
  }

  .click_change {
    display: inline-block;
    position: absolute;
    margin-left: 5px;
  }

  .members_it_ic {
    position: absolute;
    right: 8px;
    top: 15px;
    background: white;
  }

  .user_avatar {
    display: inline-block;
    position: relative;
    top: 4px;
  }
}
</style>
<style lang="scss">
.bell {
  .el-badge__content {
    background-color: #f56c6c;
    border-radius: 10px;
    color: #fff;
    display: flex;
    font-size: 12px;
    height: 16px;
    padding: 0 6px;
    text-align: center;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    border: none;
  }
}

.createpro {
  .el-dialog__body {
    padding-bottom: 0 !important;
  }
}

.el-dialog__headerbtn {
  z-index: 99999;
}

.dialog-footer-excel {
  .el-button--primary:focus {
    background-color: rgba(1, 76, 170, 1);
    border-color: rgba(1, 76, 170, 1);
  }
}

.upexcel {
  a {
    user-select: none;
    -webkit-appearance: none;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #60a3d9 !important;
    font-style: normal;
  }

  p {
    font-size: 1em;
    line-height: 140%;
  }
}

.createpro-header {
  .el-tabs__active-bar {
    background-color: #30648f;
  }

  .is-active {
    color: #30648f;
  }

  .el-tabs__item:hover {
    color: #30648f;
  }
}

.dropdown_big {
  padding: 0;
  // li {
  //   padding: 10px 15px;
  // }
  li:nth-child(1) {
    border-radius: 6px 6px 0px 0px;
    background-color: rgba(77, 160, 255, 0.35);
  }
}
.homeTopbar_dropdown_offTop {
  top: 38px !important;
}
</style>
<style lang="scss">
::v-deep .dropdown_big ::before {
  content: "32423433242343";
}

::v-deep.program_add_zdh::after {
  content: "3242343";
}

.name_logo {
  width: 210px;
  height: 30px;
}

.head-choose-project {
  input {
    border: none;
    border-radius: 6px;
    font-size: 16px;
    height: 35px;
  }

  .el-input__suffix {
    height: 35px;
  }

  .el-input__icon {
    height: 35px;
    line-height: 35px;
  }

  input::placeholder {
    color: #555;
    opacity: 1; //兼容火狐默认给占位符设置了透明度
  }
}

.search-box-hometopbar {
  margin-right: 60px;
  position: relative;

  input {
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    background-color: rgba(255, 255, 255, 0.18);
    height: 35px;
  }

  input::-webkit-input-placeholder {
    color: white;
  }

  i {
    position: absolute;
    color: #ccc;
    top: 50%;
    transform: translateY(-50%);
  }

  .el-popover {
    position: relative;
    left: 190px !important;
    z-index: 300;
    color: red !important;
    margin-top: 30px !important;
  }
}
</style>

<style lang="scss">
.icon_style_size {
  width: 22px !important;
  height: 22px !important;
}

.el-select-dropdown {
  max-width: 500px;
}

// 新增
.search-box-hometopbar {
  position: relative;
}

::v-deep .el-tabs__nav {
  margin-left: 30px !important;
}
.sea_konow {
  color: #707070;
  padding-top: 12px;
  padding-bottom: 24px;
  padding-right: 20px;
  // display: -webkit-box;
  // -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;
  cursor: pointer;
}
.sea_konow:hover {
  background-color: #f5f7fa;
}
.sea_con {
  color: #707070;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.sea_con:hover {
  background-color: #f5f7fa;
}

.el-tabs__header {
  margin-bottom: 5px !important;
}

.sea_tab .el-tab-pane {
  height: 240px !important;
  overflow: auto !important;
}
//css部分
.customer-popover {
  .driver-popover-next-btn {
    color: white;
    background: #2f64b5 !important;
  }

  // rgb(94, 198, 151)
}
</style>
<style>
.popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup-content {
  text-align: center;
}

.popup-content h2 {
  margin: 0;
  margin-bottom: 20px;
  font-size: 24px;
}

#fireworks {
  position: relative;
  width: 100%;
  height: 100px;
}

.firework {
  position: absolute;
  bottom: 0;
  width: 14px;
  height: 14px;
  background: red;
  border-radius: 50%;
  opacity: 0;
  animation: firework 1s forwards;
}

@keyframes firework {
  0% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(-200px) scale(5);
  }
}
</style>
